"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    ProfessionalHelpContact: function() {
        return ProfessionalHelpContact;
    },
    ProfessionalHelpContactAndPlace: function() {
        return ProfessionalHelpContactAndPlace;
    },
    ProfessionalHelpContacts: function() {
        return ProfessionalHelpContacts;
    },
    ProfessionalHelpPlace: function() {
        return ProfessionalHelpPlace;
    }
});
var _zod = require("zod");
var _common = require("./common");
var ProfessionalHelpContact = _common.Contact;
var ProfessionalHelpPlace = _common.Place;
var ProfessionalHelpContactAndPlace = _zod.z.object({
    contact: ProfessionalHelpContact,
    location: ProfessionalHelpPlace
});
var ProfessionalHelpContacts = _zod.z.array(ProfessionalHelpContactAndPlace).default([]);
