"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    Contact: function() {
        return Contact;
    },
    Place: function() {
        return Place;
    },
    TextItem: function() {
        return TextItem;
    }
});
var _zod = require("zod");
var TextItem = _zod.z.object({
    ID: _zod.z.string().default(''),
    text: _zod.z.string().default('')
});
var Contact = _zod.z.object({
    ID: _zod.z.string().default(''),
    firstName: _zod.z.string().default(''),
    lastName: _zod.z.string().default(''),
    localDeviceID: _zod.z.string().default(''),
    imageBase64: _zod.z.string().default(''),
    imageAssetKey: _zod.z.string().optional(),
    phone: _zod.z.string().default('')
});
var Place = _zod.z.object({
    ID: _zod.z.string().default(''),
    name: _zod.z.string().default(''),
    latitude: _zod.z.number().default(0),
    longitude: _zod.z.number().default(0)
});
