"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    Detractor: function() {
        return Detractor;
    },
    DetractorStrategy: function() {
        return DetractorStrategy;
    },
    SocialSupport: function() {
        return SocialSupport;
    },
    SupportActivity: function() {
        return SupportActivity;
    },
    Supporter: function() {
        return Supporter;
    },
    SupporterTypeEnum: function() {
        return SupporterTypeEnum;
    }
});
var _zod = require("zod");
var _common = require("./common");
var SupporterTypeEnum = {
    PARTNER: 'PARTNER',
    CHEERLEADER: 'CHEERLEADER',
    POSITIVE_ENABLER: 'POSITIVE_ENABLER'
};
var SupportActivity = _common.TextItem;
var Supporter = _zod.z.object({
    contact: _common.Contact,
    type: _zod.z.nativeEnum(SupporterTypeEnum),
    activities: _zod.z.array(SupportActivity)
});
var DetractorStrategy = _common.TextItem;
var Detractor = _zod.z.object({
    contact: _common.Contact,
    strategies: _zod.z.array(DetractorStrategy)
});
var SocialSupport = _zod.z.object({
    supporters: _zod.z.array(Supporter),
    detractors: _zod.z.array(Detractor)
}).default({
    supporters: [],
    detractors: []
});
