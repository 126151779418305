import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import {
  CompositionFieldsFragment,
  useCompositionWithTitle,
} from '@oui/app-core/src/hooks/useComposition';
import {
  BingeEatingMyPlanCompositionDataHash,
  BingeEatingMyPlanCompositionSectionHash,
} from '@oui/lib/src/bingeEatingmyStoryMyPlanComposition';
import { graphql } from '@oui/lib/src/graphql/tada';
import { CompositionTemplate } from '@oui/lib/src/types/graphql.generated';

export function useBingeEatingMyPlanComposition({
  createIfUndefined,
}: {
  createIfUndefined?: boolean;
}) {
  const { data, ...rest } = useCompositionWithTitle({
    title: 'BINGE_EATING_MY_PLAN',
    template: CompositionTemplate.BINGE_EATING_MY_PLAN,
    createIfUndefined,
  });
  const formattedData = useMemo(() => {
    return data
      ? {
          ...data,
          sections: Object.fromEntries(
            data.sections.map((section) => [section.title, section]),
          ) as BingeEatingMyPlanCompositionSectionHash,
          json: Object.fromEntries(
            data.sections.map((section) => [section.title, section.json]),
          ) as BingeEatingMyPlanCompositionDataHash,
        }
      : undefined;
  }, [data]);

  return { ...rest, data: formattedData };
}

export const UpdateBingeEatingMyPlanCompositionMutation = graphql(
  `
    mutation UpdateMyStoryMyPlanCompositionSections($json: Map!) {
      setComposition(template: BINGE_EATING_MY_PLAN, json: $json) {
        ...CompositionFields
      }
    }
  `,
  [CompositionFieldsFragment],
);

export function useUpdateBingeEatingMyPlanComposition() {
  const [update, ...rest] = useMutation(UpdateBingeEatingMyPlanCompositionMutation);

  return [
    useCallback(
      (json: BingeEatingMyPlanCompositionDataHash) => {
        return update({ variables: { json } });
      },
      [update],
    ),
    ...rest,
  ] as const;
}
