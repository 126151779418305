import { ButtonAccordion } from '@oui/app-core/src/components/Accordion';
import { Avatar } from '@oui/app-core/src/components/Avatar/Avatar';
import { Button } from '@oui/app-core/src/components/Button';
import { ListItemTextInput } from '@oui/app-core/src/components/ListItemTextInput';
import { RadioInput } from '@oui/app-core/src/components/RadioInput';
import { Label, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { getPatientSocialSupporterRelation } from '@oui/lib/src/getPatientSocialSupporterRelation';
import {
  Detractor,
  DetractorStrategy,
  SupportActivity,
  Supporter,
  SupporterTypeEnum,
} from '@oui/lib/src/types/avro';
import { Contact } from '@oui/lib/src/types/avro/common';

import { useBingeEatingMyPlanContext } from '../';

const options: Record<SupporterTypeEnum | 'DETRACTOR', { activities: string[] }> = {
  PARTNER: {
    activities: [
      'Meal prep together',
      'Set fitness goals as a team',
      'Plan healthy snacks for outings',
      'Try new healthy recipes together',
      'Track eating habits jointly',
      'Take cooking classes together',
    ],
  },
  CHEERLEADER: {
    activities: [
      'Call or connect',
      'Do activities or hobbies together',
      'Have meals together',
      'Talk to them when close to a binge',
      'Food shop together',
      'Shop for clothes together',
    ],
  },
  POSITIVE_ENABLER: {
    activities: [
      'Walk together during lunch breaks',
      'Try healthy restaurants together',
      'Share and swap healthy recipes',
      'Plan active social outings (e.g., hikes)',
      'Bring healthy snacks for group activities',
      'Choose healthier options at social events together',
    ],
  },
  DETRACTOR: {
    activities: [
      'Set boundaries around food discussions',
      'Politely decline unhealthy food offers',
      'Bring your own healthy snacks to events',
      'Suggest non-food-related activities together',
      'Change the subject when diet/food is discussed',
      'Eat before attending food-centered events',
    ],
  },
};

const UpdateSupporterRole = ({
  onSetRole,
  contact,
  currentRole,
}: {
  onSetRole: (role: SupporterTypeEnum) => void;
  contact: Supporter | Detractor;
  currentRole: SupporterTypeEnum | undefined;
}) => {
  const { $t } = useI18n();

  const items = [
    {
      label: 'Partner',
      value: SupporterTypeEnum.PARTNER,
      description: 'Someone you are in a relationship with',
    },
    {
      label: 'Cheerleader',
      value: SupporterTypeEnum.CHEERLEADER,
      description: 'Someone who supports you and cheers you on',
    },
    {
      label: 'Positive enabler',
      value: SupporterTypeEnum.POSITIVE_ENABLER,
      description: 'Someone who helps you stay on your regular, healthy eating journey',
    },
  ].map((role) => ({
    label: role.label,
    value: role.value,
    unselectedChild: <Text text={role.description} />,
    selectedChild: <Text text={role.description} />,
  }));

  return (
    <View>
      <Label
        text={$t(
          {
            id: 'UpdateSupporterRole_selectRole',
            defaultMessage: 'Select what kind of supportive person {contactName} is to you.',
          },
          { contactName: contact.contact.firstName },
        )}
      />
      <RadioInput
        style={{
          marginTop: 10,
        }}
        onChangeValue={(value: SupporterTypeEnum) => {
          onSetRole(value);
        }}
        value={currentRole ?? null}
        items={items}
        aria-label=""
      />
    </View>
  );
};

export const EditSocialSupporter = ({
  type,
  onRemove,
  onSetRole,
  contact,
  updateSupporterActivities,
  updateDetractorActivities,
}: {
  type: 'supporter' | 'detractor';
  onRemove: () => void;
  onSetRole: (role: SupporterTypeEnum) => void;
  contact: Supporter | Detractor;
  updateSupporterActivities: (contact: Contact, newActivities: SupportActivity[]) => void;
  updateDetractorActivities: (contact: Contact, newStrategies: DetractorStrategy[]) => void;
}) => {
  const { theme } = useTheme();
  const { $t } = useI18n();
  const { primaryColor } = useBingeEatingMyPlanContext();

  const fullName = `${contact.contact.firstName} ${contact.contact.lastName}`;

  const getRole = (): SupporterTypeEnum | undefined | 'DETRACTOR' => {
    if (type === 'detractor') {
      return 'DETRACTOR';
    }

    if ('type' in contact) {
      return contact.type;
    }

    return undefined;
  };

  const role = getRole();

  const getActivitiesOrStrategies = () => {
    if ('activities' in contact) {
      return contact.activities;
    } else if ('strategies' in contact) {
      return contact.strategies;
    }
    return [];
  };

  return (
    <>
      <View>
        <View
          style={{
            flexDirection: 'row',
            gap: 10,
          }}
        >
          <Avatar name={fullName} />
          <View
            row
            style={{
              flex: 1,
            }}
          >
            <Text
              style={{
                flex: 1,
              }}
              testID="SocialSupporter_name"
              weight="semibold"
              text={fullName}
            />
            <Button
              testID="SocialSupporter_removeButton"
              alignSelf="center"
              icon={'close'}
              color={theme.color.gray400}
              onPress={onRemove}
              size="small"
              aria-label="remove contact"
              variant="text"
            />
          </View>
        </View>

        <View>
          {!!role ? (
            role === 'DETRACTOR' ? (
              <Label
                color={theme.color.gray300}
                small
                style={{
                  marginLeft: 34,
                }}
                text={getPatientSocialSupporterRelation({
                  socialSupportRelation: role,
                  $t,
                })}
              />
            ) : (
              <ButtonAccordion
                style={{
                  marginLeft: 34,
                }}
                text={getPatientSocialSupporterRelation({
                  socialSupportRelation: role,
                  $t,
                })}
                defaultExpanded={!role}
              >
                <View
                  style={{
                    marginTop: 20,
                  }}
                >
                  <UpdateSupporterRole contact={contact} onSetRole={onSetRole} currentRole={role} />
                </View>
              </ButtonAccordion>
            )
          ) : null}
        </View>
      </View>
      {type === 'supporter' ? (
        <View style={{ marginTop: 20 }}>
          {!role ? (
            <UpdateSupporterRole contact={contact} onSetRole={onSetRole} currentRole={undefined} />
          ) : (
            <>
              <Label
                text={$t(
                  {
                    id: 'SocialSupport_supporterLabel',
                    defaultMessage: 'What things can you do with {contactName}?',
                  },
                  { contactName: contact.contact.firstName },
                )}
                style={{
                  marginLeft: 15,
                  marginRight: 7,
                  marginBottom: 5,
                }}
              />

              <ListItemTextInput
                aria-label={$t(
                  {
                    id: 'SocialSupport_supporterLabel',
                    defaultMessage: 'What things can you do with {contactName}?',
                  },
                  {
                    contactName: contact.contact.firstName,
                  },
                )}
                testID={`SocialSupport_activitiesInput`}
                autoFocus={!getActivitiesOrStrategies().length}
                value={getActivitiesOrStrategies() ?? []}
                onChangeValue={(newValue) => {
                  updateSupporterActivities(contact.contact, newValue);
                }}
                bulletColor={primaryColor}
                suggestions={{
                  accordionText: 'Select from a list of things to do',
                  items: options[role].activities,
                }}
                minHeight={74}
              />
            </>
          )}
        </View>
      ) : (
        <View
          style={{
            marginTop: 20,
          }}
        >
          <Label
            text={$t(
              {
                id: 'SocialSupport_detractorLabel',
                defaultMessage:
                  'What things can you do so that {contactName} doesn’t derail your progress?',
              },
              {
                contactName: contact.contact.firstName,
              },
            )}
            style={{
              marginLeft: 15,
              marginRight: 7,
              marginBottom: 5,
            }}
          />

          <ListItemTextInput
            aria-label={$t(
              {
                id: 'SocialSupport_detractorLabel',
                defaultMessage:
                  'What things can you do so that {contactName} doesn’t derail your progress?',
              },
              { contactName: contact.contact.firstName },
            )}
            testID={`SocialSupport_activitiesInput_${contact.contact.ID}`}
            autoFocus={!getActivitiesOrStrategies().length}
            value={getActivitiesOrStrategies() ?? []}
            onChangeValue={(newValue) => {
              updateDetractorActivities(contact.contact, newValue);
            }}
            bulletColor={primaryColor}
            suggestions={{
              accordionText: 'Select from a list of things to do',
              items: options.DETRACTOR.activities,
            }}
            minHeight={74}
          />
        </View>
      )}
    </>
  );
};
