"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    CrisisTimeline: function() {
        return CrisisTimeline;
    },
    CrisisTimelineEvent: function() {
        return CrisisTimelineEvent;
    },
    PeakMethod: function() {
        return PeakMethod;
    }
});
var _zod = require("zod");
var PeakMethod = _zod.z.enum([
    'gun',
    'poison',
    'drugs',
    'prescriptions',
    'suffocation',
    'hanging',
    'cutting',
    'other'
]);
var CrisisTimelineEvent = _zod.z.object({
    ID: _zod.z.string().default(''),
    isWarningSign: _zod.z.boolean().default(false),
    text: _zod.z.string().default(''),
    // To support AVIVA_MILITARY variant, we need to know what section of the suicide mode the item comes from
    suicideModeSection: _zod.z.enum([
        'afterEvents',
        'beforeEvents',
        'feelings',
        'thoughts',
        'behaviors',
        'physiological'
    ]).optional(),
    peakMethods: _zod.z.array(PeakMethod).optional()
}).describe('Stores a single warning sign');
var CrisisTimeline = _zod.z.object({
    crisisPeakID: _zod.z.string().default(''),
    /**
     * A patient crisis timeline sequence
     */ timeline: _zod.z.array(CrisisTimelineEvent).default([])
}).default({
    crisisPeakID: '',
    timeline: []
}).describe('A patient crisis timeline');
