"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    HelpContact: function() {
        return HelpContact;
    },
    HelpContacts: function() {
        return HelpContacts;
    }
});
var _zod = require("zod");
var _common = require("./common");
var HelpContact = _common.Contact;
var HelpContacts = _zod.z.array(HelpContact).default([]);
