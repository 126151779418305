"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    CSSRS: function() {
        return CSSRS;
    },
    CSSRSActivatingEvents: function() {
        return CSSRSActivatingEvents;
    },
    CSSRSBehavior: function() {
        return CSSRSBehavior;
    },
    CSSRSTreatmentHistory: function() {
        return CSSRSTreatmentHistory;
    },
    CSSRSType: function() {
        return CSSRSType;
    },
    HopelessnessResponse: function() {
        return HopelessnessResponse;
    },
    IncarcerationOrHomelessnessResponse: function() {
        return IncarcerationOrHomelessnessResponse;
    },
    InterruptedAttemptResponse: function() {
        return InterruptedAttemptResponse;
    },
    LonelinessResponse: function() {
        return LonelinessResponse;
    },
    NegativeEventResponse: function() {
        return NegativeEventResponse;
    },
    NoTreatmentResponse: function() {
        return NoTreatmentResponse;
    },
    NonComplianceResponse: function() {
        return NonComplianceResponse;
    },
    PreparatoryActsResponse: function() {
        return PreparatoryActsResponse;
    },
    PsychiatricResponse: function() {
        return PsychiatricResponse;
    },
    SelfHarmResponse: function() {
        return SelfHarmResponse;
    },
    SelfInterruptedAttemptResponse: function() {
        return SelfInterruptedAttemptResponse;
    },
    SuicidalIdeationType: function() {
        return SuicidalIdeationType;
    },
    SuicideAttemptResponse: function() {
        return SuicideAttemptResponse;
    }
});
var _zod = require("zod");
var CSSRSType = 'CSSRS';
var SuicideAttemptResponse = _zod.z.object({
    checked: _zod.z.boolean().default(false),
    recent: _zod.z.boolean().default(false),
    description: _zod.z.string().default('')
});
var InterruptedAttemptResponse = _zod.z.object({
    checked: _zod.z.boolean().default(false),
    recent: _zod.z.boolean().default(false),
    description: _zod.z.string().default('')
});
var SelfInterruptedAttemptResponse = _zod.z.object({
    checked: _zod.z.boolean().default(false),
    recent: _zod.z.boolean().default(false),
    description: _zod.z.string().default('')
});
var PreparatoryActsResponse = _zod.z.object({
    checked: _zod.z.boolean().default(false),
    recent: _zod.z.boolean().default(false),
    description: _zod.z.string().default('')
});
var SelfHarmResponse = _zod.z.object({
    checked: _zod.z.boolean().default(false),
    recent: _zod.z.boolean().default(false),
    description: _zod.z.string().default('')
});
var CSSRSBehavior = _zod.z.object({
    suicideAttempt: SuicideAttemptResponse,
    interruptedAttempt: InterruptedAttemptResponse,
    selfInterruptedAttempt: SelfInterruptedAttemptResponse,
    preparatoryActs: PreparatoryActsResponse,
    selfHarm: SelfHarmResponse
});
var SuicidalIdeationType = _zod.z.enum([
    'NON_DISCLOSE',
    'WISH_TO_BE_DEAD',
    'GENERAL_SUICIDAL_IDEATION',
    'IDEATION_WITH_METHOD_WITHOUT_INTENT',
    'IDEATION_WITH_METHOD_WITH_INTENT',
    'IDEATION_WITH_INTENT_WITHOUT_PLAN',
    'IDEATION_WITH_PLAN_WITH_INTENT'
]);
var NegativeEventResponse = _zod.z.object({
    checked: _zod.z.boolean().default(false),
    recent: _zod.z.boolean().default(false),
    description: _zod.z.string().default('')
});
var IncarcerationOrHomelessnessResponse = _zod.z.object({
    checked: _zod.z.boolean().default(false),
    recent: _zod.z.boolean().default(false),
    description: _zod.z.string().default('')
});
var LonelinessResponse = _zod.z.object({
    checked: _zod.z.boolean().default(false),
    recent: _zod.z.boolean().default(false),
    description: _zod.z.string().default('')
});
var CSSRSActivatingEvents = _zod.z.object({
    negativeEvent: NegativeEventResponse,
    incarcerationOrHomelessness: IncarcerationOrHomelessnessResponse,
    loneliness: LonelinessResponse
});
var PsychiatricResponse = _zod.z.object({
    checked: _zod.z.boolean().default(false),
    recent: _zod.z.boolean().default(false),
    description: _zod.z.string().default('')
});
var HopelessnessResponse = _zod.z.object({
    checked: _zod.z.boolean().default(false),
    recent: _zod.z.boolean().default(false),
    description: _zod.z.string().default('')
});
var NonComplianceResponse = _zod.z.object({
    checked: _zod.z.boolean().default(false),
    recent: _zod.z.boolean().default(false),
    description: _zod.z.string().default('')
});
var NoTreatmentResponse = _zod.z.object({
    checked: _zod.z.boolean().default(false),
    recent: _zod.z.boolean().default(false),
    description: _zod.z.string().default('')
});
var CSSRSTreatmentHistory = _zod.z.object({
    psychiatric: PsychiatricResponse,
    hopelessness: HopelessnessResponse,
    nonCompliance: NonComplianceResponse,
    noTreatment: NoTreatmentResponse
});
var CSSRS = _zod.z.object({
    type: _zod.z.literal(CSSRSType),
    occurrenceDate: _zod.z.string().default(''),
    behavior: CSSRSBehavior,
    mostSevereIdeation: SuicidalIdeationType.default('NON_DISCLOSE'),
    activatingEvents: CSSRSActivatingEvents,
    treatmentHistory: CSSRSTreatmentHistory,
    otherRiskFactors: _zod.z.array(_zod.z.string()).default([]),
    clinicalStatus: _zod.z.array(_zod.z.string()).default([]),
    protectiveFactors: _zod.z.array(_zod.z.string()).default([]),
    otherProtectiveFactors: _zod.z.array(_zod.z.string()).default([])
}).default({
    activatingEvents: {
        incarcerationOrHomelessness: {
            checked: false,
            description: '',
            recent: false
        },
        loneliness: {
            checked: false,
            description: '',
            recent: false
        },
        negativeEvent: {
            checked: false,
            description: '',
            recent: false
        }
    },
    behavior: {
        interruptedAttempt: {
            checked: false,
            description: '',
            recent: false
        },
        preparatoryActs: {
            checked: false,
            description: '',
            recent: false
        },
        selfHarm: {
            checked: false,
            description: '',
            recent: false
        },
        selfInterruptedAttempt: {
            checked: false,
            description: '',
            recent: false
        },
        suicideAttempt: {
            checked: false,
            description: '',
            recent: false
        }
    },
    clinicalStatus: [],
    mostSevereIdeation: 'NON_DISCLOSE',
    occurrenceDate: '',
    otherProtectiveFactors: [],
    otherRiskFactors: [],
    protectiveFactors: [],
    treatmentHistory: {
        hopelessness: {
            checked: false,
            description: '',
            recent: false
        },
        noTreatment: {
            checked: false,
            description: '',
            recent: false
        },
        nonCompliance: {
            checked: false,
            description: '',
            recent: false
        },
        psychiatric: {
            checked: false,
            description: '',
            recent: false
        }
    },
    type: 'CSSRS'
});
