"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    SocialDistractionContact: function() {
        return SocialDistractionContact;
    },
    SocialDistractionPlace: function() {
        return SocialDistractionPlace;
    },
    SocialDistractions: function() {
        return SocialDistractions;
    }
});
var _zod = require("zod");
var _common = require("./common");
var SocialDistractionContact = _common.Contact;
var SocialDistractionPlace = _common.Place;
var SocialDistractions = _zod.z.object({
    contacts: _zod.z.array(SocialDistractionContact).default([]),
    places: _zod.z.array(SocialDistractionPlace).default([])
}).default({
    contacts: [],
    places: []
});
