"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "ReasonsForLiving", {
    enumerable: true,
    get: function() {
        return ReasonsForLiving;
    }
});
var _zod = require("zod");
var _common = require("./common");
var ReasonsForLiving = _zod.z.array(_common.TextItem).default([]);
