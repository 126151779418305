"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    DistractingActivities: function() {
        return DistractingActivities;
    },
    DistractingActivity: function() {
        return DistractingActivity;
    }
});
var _zod = require("zod");
var _common = require("./common");
var DistractingActivity = _common.TextItem;
var DistractingActivities = _zod.z.array(DistractingActivity).default([]);
