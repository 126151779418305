"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    EatingCommitment: function() {
        return EatingCommitment;
    },
    EatingCommitmentHealthyFoodEnum: function() {
        return EatingCommitmentHealthyFoodEnum;
    },
    EatingCommitmentUnhealthyFoodEnum: function() {
        return EatingCommitmentUnhealthyFoodEnum;
    }
});
var _zod = require("zod");
var EatingCommitmentHealthyFoodEnum = {
    FRUITS: 'FRUITS',
    VEGETABLES: 'VEGETABLES',
    LEAN_MEATS: 'LEAN_MEATS',
    GRAINS: 'GRAINS',
    HEALTHY_FATS: 'HEALTHY_FATS',
    OTHER: 'OTHER'
};
var EatingCommitmentUnhealthyFoodEnum = {
    SODA: 'SODA',
    TAKEOUT: 'TAKEOUT',
    DESSERTS: 'DESSERTS',
    JUNK_FOOD: 'JUNK_FOOD',
    CANDY: 'CANDY',
    OTHER: 'OTHER'
};
var EatingCommitment = _zod.z.object({
    increase: _zod.z.nativeEnum(EatingCommitmentHealthyFoodEnum),
    increaseOther: _zod.z.string().optional(),
    decrease: _zod.z.nativeEnum(EatingCommitmentUnhealthyFoodEnum),
    decreaseOther: _zod.z.string().optional()
}).default({
    increase: 'FRUITS',
    decrease: 'SODA'
});
