"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    EnvironmentSafety: function() {
        return EnvironmentSafety;
    },
    EnvironmentSafetyStep: function() {
        return EnvironmentSafetyStep;
    },
    SupportContact: function() {
        return SupportContact;
    }
});
var _zod = require("zod");
var _common = require("./common");
var EnvironmentSafetyStep = _common.TextItem;
var SupportContact = _common.Contact;
var EnvironmentSafety = _zod.z.object({
    /**
     * A list of removal steps
     */ removalSteps: _zod.z.array(EnvironmentSafetyStep).default([]),
    /**
     * A list of environment safety support contacts
     */ supportContacts: _zod.z.array(SupportContact).default([])
}).default({
    removalSteps: [],
    supportContacts: []
});
