"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    RiskCurve: function() {
        return RiskCurve;
    },
    RiskFactors: function() {
        return RiskFactors;
    }
});
var _zod = require("zod");
var _common = require("./common");
var RiskCurve = _common.TextItem;
var RiskFactors = _zod.z.array(RiskCurve).default([]);
