import hexToRgba from 'hex-to-rgba';
import { useCallback, useMemo } from 'react';

import { ListItemTextInput } from '@oui/app-core/src/components/ListItemTextInput';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';
import { BingeEatingMyPlanCompositionSectionHash } from '@oui/lib/src/bingeEatingmyStoryMyPlanComposition';
import { recordKeys } from '@oui/lib/src/recordKeys';
import { MoodPlanMoodEnum, MoodPlanStrategy } from '@oui/lib/src/types/avro';

import { Card, MoodPicker, Section, useBingeEatingMyPlanContext } from '../';

const options = {
  SAD: {
    label: 'Sad',
    suggestions: [
      'Write in a gratitude journal',
      'Listen to uplifting music',
      'Call or text a friend',
      'Practice deep breathing exercises',
      'Take a soothing bath',
      'Engage in a creative hobby',
      'Try a relaxation meditation',
      'Take a short walk in nature',
      'Watch a comforting show',
    ],
  },
  STRESSED: {
    label: 'Stressed',
    suggestions: [
      'Try progressive muscle relaxation',
      'Take 10 deep breaths',
      'Do a 5-minute stretch',
      'Write a to-do list',
      'Practice mindfulness for 5 minutes',
      'Sip herbal tea slowly',
      'Take a short break to step outside',
      'Do a quick body scan meditation',
      'Use positive affirmations',
    ],
  },
  GUILTY: {
    label: 'Guilty',
    suggestions: [
      'Write down the source of guilt',
      'Practice self-compassion exercises',
      'Forgive yourself for past mistakes',
      'Talk it out with a trusted friend',
      'Reflect on what you’ve learned',
      'Do a short yoga session',
      'Remind yourself of your progress',
      'Engage in a distracting activity',
      'Write a forgiveness letter',
    ],
  },
  BORED: {
    label: 'Bored',
    suggestions: [
      'Embrace boredom',
      'Connect with friends or family',
      'Take a walk outside',
      'Journal your thoughts',
      'Read a positive book',
      'Watch a feel-good movie',
      'Rekindle an old hobby',
      'Do an outdoor exercise',
      'Listen to an inspiring podcast',
    ],
  },
  ANGRY: {
    label: 'Angry',
    suggestions: [
      'Do a 5-minute workout',
      'Practice deep breathing',
      'Write an anger letter (don’t send)',
      'Try a calming breathing exercise',
      'Listen to calming music',
      'Squeeze a stress ball',
      'Take a timeout and go for a walk',
      'Count to 10 before reacting',
      'Reflect on the source of anger',
    ],
  },
  HAPPY: {
    label: 'Happy',
    suggestions: [
      'Share your happiness with others',
      'Dance to your favorite song',
      'Treat yourself to a non-food reward',
      'Capture the moment in a journal',
      'Celebrate by doing something creative',
      'Call someone to share the good news',
      'Take a nature walk to extend joy',
      'Engage in an exciting project',
      'Plan a future activity to look forward to',
    ],
  },
};

export const MOOD_PLAN_OPTIONS = options;

export function MoodPlan({
  data,
  onEdit,
}: {
  data: BingeEatingMyPlanCompositionSectionHash['MOOD_PLAN'];
  onEdit: (data: Partial<BingeEatingMyPlanCompositionSectionHash['MOOD_PLAN']>) => void;
}) {
  const { theme } = useTheme();
  const { primaryColor } = useBingeEatingMyPlanContext();

  const activeMoods = data.json.moods;

  const sortedOptions = useMemo(() => {
    return activeMoods.slice().sort((a, b) => {
      const order = Object.keys(options);
      return order.indexOf(a.mood) - order.indexOf(b.mood);
    });
  }, [activeMoods]);

  const moodPickerOptions = useMemo(
    () =>
      recordKeys(options).map((key) => ({
        label: options[key].label,
        active: activeMoods.some((m) => m.mood === key),
      })),
    [activeMoods],
  );

  const toggleActiveMood = useCallback(
    (moodLabel: string) => {
      const mood = recordKeys(options).find((key) => options[key].label === moodLabel);
      if (!mood) return;

      const updatedMoods = activeMoods.some((m) => m.mood === mood)
        ? activeMoods.filter((m) => m.mood !== mood)
        : [...activeMoods, { mood, strategy: [] }];

      return onEdit({
        ...data,
        json: {
          moods: updatedMoods,
        },
      });
    },
    [activeMoods, data, onEdit],
  );

  const updateMoodStrategy = useCallback(
    (mood: MoodPlanMoodEnum, newStrategy: MoodPlanStrategy[]) => {
      return onEdit({
        ...data,
        json: {
          moods: activeMoods.map((m) => {
            if (m.mood === mood) {
              return {
                mood: m.mood,
                strategy: newStrategy,
              };
            }
            return m;
          }),
        },
      });
    },
    [data, activeMoods, onEdit],
  );

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: theme.color.gray800 }}
      contentContainerStyle={{ paddingBottom: 20 }}
      testID="MoodPlan_scrollView"
    >
      <Card title="Mood plan">
        <Text
          color={theme.color.gray300}
          text="Certain moods lead to people to overeat and binge. First, pick 2-3 moods that you struggle with. Then list strategies that could work for you for each mood."
        />

        <View
          style={{
            marginTop: 15,
            gap: 40,
          }}
        >
          <Section title="Select 2-3 moods">
            <MoodPicker onPress={toggleActiveMood} options={moodPickerOptions} />
          </Section>

          <View
            style={{
              gap: 25,
            }}
          >
            {sortedOptions.map((item) => {
              return (
                <Section
                  key={item.mood}
                  title={`When I feel ${options[item.mood].label.toLocaleLowerCase()}`}
                  headerBackgroundColor={hexToRgba(theme.color.accentTwo300, 0.3)}
                  testID={`MoodPlan_Section_${item.mood}`}
                >
                  <ListItemTextInput
                    testID="MoodPlan_moodPlanInput"
                    aria-label={undefined}
                    autoFocus={!item.strategy.length}
                    value={item.strategy ?? []}
                    onChangeValue={(newMoodPlan) => {
                      updateMoodStrategy(item.mood, newMoodPlan);
                    }}
                    bulletColor={primaryColor}
                    suggestions={{
                      accordionText: 'Select from suggested ways',
                      items: options[item.mood].suggestions,
                    }}
                    minHeight={74}
                  />
                </Section>
              );
            })}
          </View>
        </View>
      </Card>
    </ScrollView>
  );
}
