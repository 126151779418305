"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    ChecklistItem: function() {
        return ChecklistItem;
    },
    ClinicalChecklist: function() {
        return ClinicalChecklist;
    }
});
var _zod = require("zod");
var ChecklistItem = _zod.z.object({
    key: _zod.z.string().default(''),
    completed: _zod.z.boolean().default(false)
});
var ClinicalChecklist = _zod.z.object({
    checklist: _zod.z.array(ChecklistItem).default([])
}).default({
    checklist: []
});
