"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    WarningSign: function() {
        return WarningSign;
    },
    WarningSigns: function() {
        return WarningSigns;
    }
});
var _zod = require("zod");
var _common = require("./common");
var WarningSign = _common.TextItem;
var WarningSigns = _zod.z.array(WarningSign).default([]);
