"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    MoodPlan: function() {
        return MoodPlan;
    },
    MoodPlanMoodEnum: function() {
        return MoodPlanMoodEnum;
    },
    MoodPlanStrategy: function() {
        return MoodPlanStrategy;
    }
});
var _zod = require("zod");
var _common = require("./common");
var MoodPlanStrategy = _common.TextItem;
var MoodPlanMoodEnum = {
    SAD: 'SAD',
    STRESSED: 'STRESSED',
    GUILTY: 'GUILTY',
    BORED: 'BORED',
    ANGRY: 'ANGRY',
    HAPPY: 'HAPPY'
};
var MoodPlan = _zod.z.object({
    moods: _zod.z.array(_zod.z.object({
        mood: _zod.z.nativeEnum(MoodPlanMoodEnum),
        strategy: _zod.z.array(MoodPlanStrategy)
    }))
}).default({
    moods: []
});
