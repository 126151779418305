import { useQuery } from '@apollo/client';
import { useFocusEffect, useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import addDays from 'date-fns/addDays';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { SystemBars } from 'react-native-edge-to-edge';

import { DiaryTabs } from '@oui/app-core/src/components/DiaryTabs';
import { HeaderButtons } from '@oui/app-core/src/components/HeaderButtons';
import { OverflowMenu, OverflowMenuOption } from '@oui/app-core/src/components/OverflowMenu';
import {
  RoundedSection,
  RoundedSectionNavigationOptions,
} from '@oui/app-core/src/components/RoundedSection';
import { TabHeader } from '@oui/app-core/src/components/TabHeader';
import { View } from '@oui/app-core/src/components/View';
import { useDefaultHeaderHeight } from '@oui/app-core/src/lib/getDefaultHeaderHeight';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { formatGQLDate } from '@oui/lib/src/gqlDate';
import { graphql } from '@oui/lib/src/graphql/tada';
import { GQLDate } from '@oui/lib/src/types/scalars';

import { EatingLogEntries, EditEatingCommitments } from '../components';
import { EatingCommitments } from '../components/EatingCommitments/EatingCommitments';
import { StackScreenProps } from '../types/navigation';

export const EatingLogQuery = graphql(`
  query EatingLog {
    user {
      ID
      role {
        ID
        progress {
          content
          completed
        }
        eatingCommitmentComposition: composition(template: "EATING_COMMITMENT") {
          ID
        }
        eatingScheduleComposition: composition(template: "EATING_SCHEDULE") {
          ID
        }
      }
    }
  }
`);

function getInitialDate(date?: string): GQLDate | undefined {
  if (date === 'yesterday') {
    return formatGQLDate(addDays(new Date(), -1));
  }

  return date as GQLDate | undefined;
}

export function EatingLog(_props: {}) {
  const { $t } = useI18n();
  const navigation = useNavigation<StackScreenProps<'EatingLog'>['navigation']>();
  const route = useRoute<StackScreenProps<'EatingLog'>['route']>();
  const tab = route.params?.tab ? route.params.tab !== 'false' : true;
  const { data, refetch } = useQuery(EatingLogQuery);
  const isFocused = useIsFocused();
  const { theme } = useTheme();
  const [activeTab, setActiveTab] = useState<'log' | 'commitment'>('log');

  const role = data?.user?.role;

  const scheduleEnabled = !!role?.progress.find((p) => p.content === 'REGULAR_EATING')?.completed;
  const commitmentsEnabled = !!role?.progress.find((p) => p.content === 'EATING_THE_RIGHT_FOODS')
    ?.completed;
  const hasEatingSchedule = !!role?.eatingScheduleComposition?.ID;
  const hasEatingCommitments = !!role?.eatingCommitmentComposition?.ID;

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  const menu = useMemo(() => {
    return scheduleEnabled || commitmentsEnabled ? (
      <OverflowMenu
        triggerTestID="EatingLog_overflowMenuTrigger"
        headerItem
        triggerColor={theme.color.primary100}
      >
        {hasEatingSchedule ? (
          <OverflowMenuOption
            icon="meal"
            text="Edit eating schedule"
            testID="EatingLog_editEatingSchedule"
            onPress={() => navigation.navigate('EditEatingSchedule', {})}
          />
        ) : scheduleEnabled ? (
          <OverflowMenuOption
            icon="meal"
            text="Setup eating schedule"
            testID="EatingLog_setupEatingSchedule"
            onPress={() => navigation.navigate('EditEatingSchedule', {})}
          />
        ) : null}
        {hasEatingCommitments ? (
          <OverflowMenuOption
            icon="meal"
            text="Edit commitments"
            testID="EatingLog_editEatingCommitments"
            onPress={() => navigation.navigate('EditEatingCommitments', {})}
          />
        ) : commitmentsEnabled ? (
          <OverflowMenuOption
            icon="meal"
            text="Setup commitments"
            testID="EatingLog_setupEatingCommitments"
            onPress={() => navigation.navigate('EditEatingCommitments', {})}
          />
        ) : null}
      </OverflowMenu>
    ) : null;
  }, [
    commitmentsEnabled,
    scheduleEnabled,
    hasEatingSchedule,
    hasEatingCommitments,
    navigation,
    theme,
  ]);

  const inner = (
    <>
      {commitmentsEnabled ? (
        <View style={{ marginTop: 12 }}>
          <DiaryTabs
            items={[
              { text: 'Meals', value: 'log', icon: 'meal' },
              { text: 'Commitment', icon: 'check-in', value: 'commitment' },
            ]}
            value={activeTab}
            onChangeValue={setActiveTab}
          />
        </View>
      ) : null}
      {activeTab === 'log' ? (
        <EatingLogEntries date={getInitialDate(route.params?.date)} paddingBottom={tab ? 150 : 0} />
      ) : hasEatingCommitments ? (
        <EatingCommitments />
      ) : (
        <EditEatingCommitments onSave={refetch} />
      )}
    </>
  );

  const defaultHeaderHeight = useDefaultHeaderHeight();
  useLayoutEffect(() => {
    if (!tab) {
      navigation.setOptions({
        ...RoundedSectionNavigationOptions({
          backgroundColor: 'white',
          tintColor: theme.color.primary100,
          defaultHeaderHeight,
        }),
        headerRight: () => <HeaderButtons>{menu}</HeaderButtons>,
      });
    }
  }, [tab, menu, navigation, defaultHeaderHeight, theme]);

  return tab ? (
    <View style={{ flex: 1 }} testID="EatingLog">
      {isFocused ? <SystemBars style="dark" /> : null}
      <TabHeader
        heading={$t({ id: 'EatingLog_heading', defaultMessage: 'Eating log' })}
        headerRight={menu}
      />
      {inner}
    </View>
  ) : (
    <RoundedSection color="white" title="Eating log" noScrollView testID="EatingLog">
      <View
        style={{
          marginTop: -20,
          marginBottom: 20,
          marginHorizontal: -20,
          borderTopStartRadius: 30,
          borderTopEndRadius: 30,
          overflow: 'hidden',
          flex: 1,
        }}
      >
        {inner}
      </View>
    </RoundedSection>
  );
}
