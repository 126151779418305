"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    CurrentEditorEnum: function() {
        return CurrentEditorEnum;
    },
    MyStoryMyPlanState: function() {
        return MyStoryMyPlanState;
    },
    MyStoryMyPlanStep: function() {
        return MyStoryMyPlanStep;
    }
});
var _zod = require("zod");
var MyStoryMyPlanStep = _zod.z.enum([
    'OVERVIEW',
    'MYSTORY__INTRODUCTION',
    'MYSTORY__TIMELINE',
    'MYSTORY__TIMELINE_REVIEW',
    'MYSTORY__TIMELINE_FINAL',
    'RISK_CURVE__INTRODUCTION',
    'RISK_CURVE__REVIEW',
    'MY_PLAN__INTRODUCTION',
    'MY_PLAN__REVIEW',
    'COMPLETE'
]);
var CurrentEditorEnum = _zod.z.enum([
    'CLINICIAN',
    'PATIENT__SOCIAL_CONTACT_PICKER',
    'PATIENT__SOCIAL_PLACE_PICKER',
    'PATIENT__HELP_CONTACT_PICKER',
    'PATIENT__PROFESSIONAL_CONTACT_PICKER',
    'PATIENT__SUPPORT_CONTACT_PICKER'
]);
var MyStoryMyPlanState = _zod.z.object({
    currentStep: MyStoryMyPlanStep.default('OVERVIEW'),
    currentEditor: CurrentEditorEnum.default('CLINICIAN')
}).default({
    currentStep: 'OVERVIEW',
    currentEditor: 'CLINICIAN'
});
