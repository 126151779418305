"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    CompositionTemplates: function() {
        return CompositionTemplates;
    },
    SingletonCompositionSectionTemplates: function() {
        return SingletonCompositionSectionTemplates;
    },
    getBlankDocument: function() {
        return getBlankDocument;
    },
    getBlankSingletonDocument: function() {
        return getBlankSingletonDocument;
    }
});
var _uuid = require("uuid");
var _zod = require("zod");
var _avro = require("./avro");
var _stressSensitivityAssessment = require("./avro/stressSensitivityAssessment");
var _ystMentalHealthConcerns = require("../ystMentalHealthConcerns");
var _ystScheduler = require("../ystScheduler");
var SingletonCompositionSectionTemplates = {
    EATING_COMMITMENT: _avro.EatingCommitment,
    EATING_SCHEDULE: _avro.EatingSchedule,
    MYSTORYMYPLAN_CLINICAL: _avro.ClinicalChecklist,
    SCREENER_CSSRS: _avro.CSSRS,
    SCREENER_NUMERIC: _avro.NumericDiagnostic,
    STRESS_SENSITIVITY_ASSESSMENT: _stressSensitivityAssessment.StressSensitivityAssessment,
    TEST: _zod.z.object({}).default({}),
    YST_MENTAL_HEALTH_CONCERNS: _ystMentalHealthConcerns.YstMentalHealthConcerns,
    YST_ONBOARDING_AVAILABILITY: _ystScheduler.YstOnboardingAvailability,
    YST_RECURRING_AVAILABILITY: _ystScheduler.YstRecurringAvailability
};
var CompositionTemplates = {
    // singleton composition templates
    EATING_COMMITMENT: _zod.z.object({
        EATING_COMMITMENT: SingletonCompositionSectionTemplates.EATING_COMMITMENT
    }).strict().default({
        EATING_COMMITMENT: SingletonCompositionSectionTemplates.EATING_COMMITMENT.parse(undefined)
    }),
    EATING_SCHEDULE: _zod.z.object({
        EATING_SCHEDULE: SingletonCompositionSectionTemplates.EATING_SCHEDULE
    }).strict().default({
        EATING_SCHEDULE: SingletonCompositionSectionTemplates.EATING_SCHEDULE.parse(undefined)
    }),
    TEST: _zod.z.object({
        TEST: SingletonCompositionSectionTemplates.TEST
    }).strict().default({
        TEST: SingletonCompositionSectionTemplates.TEST.parse(undefined)
    }),
    SCREENER_NUMERIC: _zod.z.object({
        SCREENER_NUMERIC: SingletonCompositionSectionTemplates.SCREENER_NUMERIC
    }).strict().default({
        SCREENER_NUMERIC: SingletonCompositionSectionTemplates.SCREENER_NUMERIC.parse(undefined)
    }),
    SCREENER_CSSRS: _zod.z.object({
        SCREENER_CSSRS: SingletonCompositionSectionTemplates.SCREENER_CSSRS
    }).strict().default({
        SCREENER_CSSRS: SingletonCompositionSectionTemplates.SCREENER_CSSRS.parse(undefined)
    }),
    STRESS_SENSITIVITY_ASSESSMENT: _zod.z.object({
        STRESS_SENSITIVITY_ASSESSMENT: SingletonCompositionSectionTemplates.STRESS_SENSITIVITY_ASSESSMENT
    }).strict().default({
        STRESS_SENSITIVITY_ASSESSMENT: SingletonCompositionSectionTemplates.STRESS_SENSITIVITY_ASSESSMENT.parse(undefined)
    }),
    MYSTORYMYPLAN_CLINICAL: _zod.z.object({
        MYSTORYMYPLAN_CLINICAL: SingletonCompositionSectionTemplates.MYSTORYMYPLAN_CLINICAL
    }).strict().default({
        MYSTORYMYPLAN_CLINICAL: SingletonCompositionSectionTemplates.MYSTORYMYPLAN_CLINICAL.parse(undefined)
    }),
    YST_MENTAL_HEALTH_CONCERNS: _zod.z.object({
        YST_MENTAL_HEALTH_CONCERNS: SingletonCompositionSectionTemplates.YST_MENTAL_HEALTH_CONCERNS
    }).strict().default({
        YST_MENTAL_HEALTH_CONCERNS: SingletonCompositionSectionTemplates.YST_MENTAL_HEALTH_CONCERNS.parse(undefined)
    }),
    YST_ONBOARDING_AVAILABILITY: _zod.z.object({
        YST_ONBOARDING_AVAILABILITY: _ystScheduler.YstOnboardingAvailability
    }).strict().default({
        YST_ONBOARDING_AVAILABILITY: SingletonCompositionSectionTemplates.YST_ONBOARDING_AVAILABILITY.parse(undefined)
    }),
    YST_RECURRING_AVAILABILITY: _zod.z.object({
        YST_RECURRING_AVAILABILITY: _ystScheduler.YstRecurringAvailability
    }).strict().default({
        YST_RECURRING_AVAILABILITY: SingletonCompositionSectionTemplates.YST_RECURRING_AVAILABILITY.parse(undefined)
    }),
    BINGE_EATING_MY_PLAN: _zod.z.object({
        APP_STATE: _avro.MyStoryMyPlanState,
        DISTRACTING_ACTIVITIES: _avro.DistractingActivities,
        MOOD_PLAN: _avro.MoodPlan,
        SOCIAL_SUPPORT: _avro.SocialSupport,
        PREVENTING_RELAPSE: _avro.PreventingRelapse
    }).strict().default({
        APP_STATE: _avro.MyStoryMyPlanState.parse(undefined),
        DISTRACTING_ACTIVITIES: _avro.DistractingActivities.parse(undefined),
        MOOD_PLAN: _avro.MoodPlan.parse(undefined),
        SOCIAL_SUPPORT: _avro.SocialSupport.parse(undefined),
        PREVENTING_RELAPSE: _avro.PreventingRelapse.parse(undefined)
    }),
    MYSTORYMYPLAN: _zod.z.object({
        APP_STATE: _avro.MyStoryMyPlanState,
        CRISIS_TIMELINE: _avro.CrisisTimeline,
        WARNING_SIGNS: _avro.WarningSigns,
        COPING_STRATEGIES: _avro.CopingStrategies,
        REASONS_FOR_LIVING: _avro.ReasonsForLiving.optional(),
        SOCIAL_DISTRACTIONS: _avro.SocialDistractions,
        HELP_CONTACTS: _avro.HelpContacts,
        PROFESSIONAL_HELP_CONTACTS: _avro.ProfessionalHelpContacts,
        ENVIRONMENT_SAFETY: _avro.EnvironmentSafety,
        RISK_FACTORS: _avro.RiskFactors
    }).strict().default({
        APP_STATE: _avro.MyStoryMyPlanState.parse(undefined),
        CRISIS_TIMELINE: _avro.CrisisTimeline.parse(undefined),
        WARNING_SIGNS: _avro.WarningSigns.parse(undefined),
        COPING_STRATEGIES: _avro.CopingStrategies.parse(undefined),
        REASONS_FOR_LIVING: _avro.ReasonsForLiving.parse(undefined),
        SOCIAL_DISTRACTIONS: _avro.SocialDistractions.parse(undefined),
        HELP_CONTACTS: _avro.HelpContacts.parse(undefined),
        PROFESSIONAL_HELP_CONTACTS: _avro.ProfessionalHelpContacts.parse(undefined),
        ENVIRONMENT_SAFETY: _avro.EnvironmentSafety.parse(undefined),
        RISK_FACTORS: _avro.RiskFactors.parse(undefined)
    })
};
function getBlankDocument(template, param) {
    var product = param.product;
    var document = CompositionTemplates[template].parse(undefined);
    if (product.slug == 'AVIVA_INPATIENT' && 'COPING_STRATEGIES' in document) {
        document.COPING_STRATEGIES = [
            {
                ID: (0, _uuid.v4)(),
                text: 'Do relaxation or mindfulness'
            }
        ];
    }
    return document;
}
function getBlankSingletonDocument(template) {
    return SingletonCompositionSectionTemplates[template].parse(undefined);
}
