"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    PreventingRelapse: function() {
        return PreventingRelapse;
    },
    PreventingRelapseReasonEnum: function() {
        return PreventingRelapseReasonEnum;
    },
    PreventingRelapseStrategy: function() {
        return PreventingRelapseStrategy;
    }
});
var _zod = require("zod");
var _common = require("./common");
var PreventingRelapseStrategy = _common.TextItem;
var PreventingRelapseReasonEnum = {
    LOSS: 'LOSS',
    FINANCIAL: 'FINANCIAL',
    CONFLICT: 'CONFLICT',
    STRESS: 'STRESS',
    CHANGES: 'CHANGES',
    HOLIDAYS: 'HOLIDAYS',
    VACATIONS: 'VACATIONS',
    OTHER: 'OTHER'
};
var PreventingRelapse = _zod.z.object({
    relapsePlans: _zod.z.array(_zod.z.object({
        reason: _zod.z.nativeEnum(PreventingRelapseReasonEnum),
        reasonLabel: _zod.z.string().optional(),
        strategies: _zod.z.array(PreventingRelapseStrategy)
    }))
}).default({
    relapsePlans: []
});
