"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "NumericDiagnostic", {
    enumerable: true,
    get: function() {
        return NumericDiagnostic;
    }
});
var _zod = require("zod");
var NumericDiagnostic = _zod.z.object({
    type: _zod.z.enum([
        'UNKNOWN',
        'BECK',
        'PHQ9',
        'SCS',
        'BSCS'
    ]).default('UNKNOWN'),
    occurrenceDate: _zod.z.string().default(''),
    responses: _zod.z.array(_zod.z.number()).default([])
}).default({
    occurrenceDate: '',
    responses: [],
    type: 'UNKNOWN'
});
