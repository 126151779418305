"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    CopingStrategies: function() {
        return CopingStrategies;
    },
    CopingStrategy: function() {
        return CopingStrategy;
    }
});
var _zod = require("zod");
var _common = require("./common");
var CopingStrategy = _common.TextItem;
var CopingStrategies = _zod.z.array(CopingStrategy).default([]);
